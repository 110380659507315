<template>
  <div id="section-post">
    <navigator />
    <a class="goBack" href="/blog-es"><img src="../../assets/arrow2.svg" alt="">Volver al blog </a>

    <div id="cnt-post">

      <div id="body-post">
        <h1 class="title-post">{{ post.title }}</h1>
        <p class="post-date">{{ convertdate(post.dateblog) }}</p>
        <div class="post-img">
          <img :src="srcImg(post.image)" alt="" />
        </div>
        <div class="post-sec1">

          <p v-if="isArray(post.tags)" class="post-subtitle">
            <span v-for="(item, index) in post.tags" :key="index">{{ item.name }}
              <span v-if="index < post.tags.length - 1">, </span>
            </span>
          </p>
        </div>
        <hr />
        <!--  <div class="content-post" v-html="post.content"> </div> -->
        <div v-for="(bloq, key) in post.body" :key="key" class="bloq-item content-post">
          <div class="mbbloq">
            <div v-if="bloq.tipo == 'texto'" v-html="bloq.texto" class="S1F1"></div>
          </div>

          <div v-if="bloq.tipo == 'video'" v-html="bloq.video" class="video"></div>

          <div v-if="bloq.tipo == 'frase'" class="fraseSq">
            <p class="B2Mesage" :class="{ B2Mesage2: bloq.tipo != 'frase' }">
              “{{ bloq.frase.texto }}”.
            </p>
            <p class="B2Autor">/ {{ bloq.frase.firma }}</p>
          </div>

          <div v-if="bloq.tipo == 'frase_destacada'" class="mbbloq">
            <p class="B4F5_P">"{{ bloq.frase_destacada }}"</p>
          </div>

          <img v-if="bloq.tipo == 'imagen'" :src="srcImg(bloq.imagen)" alt="imagen bloque" />
          <div v-if="bloq.tipo == 'caja'" class="B2F1">
            <div class="col-caja">
              <div v-if="bloq.caja[0].tipo == 'texto'" v-html="bloq.caja[0].texto" class="B3F1_P"></div>
              <img class="img-blog" v-if="bloq.caja[0].tipo == 'imagen'" :src="srcImg(bloq.caja[0].imagen)"
                alt="imagen bloque" />

              <div v-if="bloq.caja[0].tipo == 'frase'" class="fraseSq">
                <p class="B2Mesage">“{{ bloq.caja[0].frase.texto }}”.</p>
                <p class="B2Autor">/ {{ bloq.caja[0].frase.firma }}</p>
              </div>

              <div v-if="bloq.caja[0].tipo == 'frase_destacada'">
                <p class="B3F4_P">"{{ bloq.caja[0].frase_destacada }}"</p>
              </div>
            </div>

            <div class="mlfcaja2 col-caja">
              <div v-if="bloq.caja[1].tipo == 'texto'" v-html="bloq.caja[1].texto" class="B3F1_P"></div>

              <img v-if="bloq.caja[1].tipo == 'imagen'" :src="srcImg(bloq.caja[1].imagen)" alt="imagen bloque" />

              <div v-if="bloq.caja[1].tipo == 'frase'" class="fraseSq">
                <p class="B2Mesage">“{{ bloq.caja[1].frase.texto }}”.</p>
                <p class="B2Autor">/ {{ bloq.caja[1].frase.firma }}</p>
              </div>

              <div v-if="bloq.caja[1].tipo == 'frase_destacada'">
                <p class="B3F4_P">"{{ bloq.caja[1].frase_destacada }}"</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navigator from "@/components/SimpleNavigator";
import image from "@/assets/blog/img3.png";
import Footer from "@/components/landing/LandingFooter.vue";

import { mapActions } from "vuex";
import { url, endpointCodes } from "../../global/index";
import es from 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'

export default {
  name: "PostBlogView",
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {
      url: "",
      image,

      opcionesD: {
        infinite: true,
        autoplay: true,
        slidesToShow: 3,
        navButtons: false,
        dots: false
      },
      metaTitle: '',
      metaDescription: ''
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: 'description',
          content: this.metaDescription,
        }
      ]
    };
  },
  async created() {
    this.url = this.$route.params;

    //todo: mandar solicitud al backend para el blog by url
    const datapost = await this.getInfoByUrlBlo({
      option: "blog-url",
      url: this.$route.params.url,
    });

    
    if(datapost?.meta_title && datapost?.meta_description){
      this.metaTitle = datapost.meta_title;
      this.metaDescription = datapost.meta_description;

      // Forzar la actualización de las metaetiquetas
      this.$meta().refresh();
    }
  },
  computed: {
    post() {
      const data = this.$store.getters["blog/data"];
      return data;
    },
  },
  methods: {
    ...mapActions("blog", ["getInfoByUrlBlo"]),

    status_img: async function (img) {
      let payload = {
        option: "blog",
        image: img,
      };
      let response = "";

      try {
        response = await this.$store.dispatch("main/getStatusImage", payload);

        if (!response?.data?.status) {
        } else {
          response = "";
        }
      } catch (err) {
        //console.log(err)
      }

      return response;
    },

    srcImg: function (img) {
      let src = `${url}/${endpointCodes.get}/blog-img/${img}`;

      return src;
    },

    isArray(array) {
      return Array.isArray(array);
    },
    convertdate: function (date) {
      const dayjs = require('dayjs');
      dayjs.extend(relativeTime)
      let newsdata = dayjs(date).locale(es).format('YYYY-MMMM-DD')
      let fecha = newsdata.split('-')
      let dd = fecha[2]
      let mm = fecha[1]
      let yy = fecha[0]
      return fecha = mm + ' ' + dd + ', ' + yy
    },
  },
};
</script>
<style scoped>
#section-post {
  background-image: url(/img/log.2a44d059.png);
  background-repeat: no-repeat;
  background-size: 58.7vw;
  background-position: 41.3vw 30.5vw;
}

#cnt-post {}

h1.title-post {
  text-align: left;
  margin: 0px auto;
  color: lineart;
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 5.841121495327103vw;
  margin-top: 5.84vw;
  line-height: 10.28vw;

  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

#body-post {
  width: 78.97196261682244vw;
  overflow: hidden;

  margin: 0px auto;
  margin-bottom: 34.81308411214953vw;
}

p.post-date {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 2.336448598130841vw;
  line-height: 134%;
  /* or 13px */

  color: #676467;

  text-transform: capitalize;
  margin-bottom: 9vw;
}

.post-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.post-img img {
  width: 100%;
  height: 54.67289719626168vw;
}

.post-sec1 {
  display: flex;
  margin-top: 3.9719626168224296vw;
}

p.post-subtitle,
p.post-autor {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 2.336448598130841vw;
  line-height: 134%;
  /* or 13px */

  color: #676467;
}

p.post-autor {
  width: 30%;
}

p.post-subtitle {
  width: 70%;
  text-align: right;
}

hr {
  margin-bottom: 9.11214953271028vw;
}

.content-post {
  font-style: normal;
  font-weight: 400;
  font-size: 3.302vw;

  /* line-height: 152.68%; or 21px */

  color: #676467;
}

:deep(.content-post > h1) {
  font-size: 3.302vw;
  font-family: var(--MOSB_Regular);
}

:deep(.content-post > h2) {
  font-size: 3.302vw;
  font-family: var(--Open_Sans);
  margin: 9vw 0;
}

:deep(.content-post > p) {
  font-family: var(--Open_Sans);
  font-size: 3.302vw;
}

:deep(.content-post p) {
  margin: 0;
}

@media (min-width: 1024px) {
  #body-post {
    width: 67.29166666666667vw;
    margin-bottom: 13.645833333333332vw;
  }

  hr {
    margin-bottom: 4.11215vw;
  }

  h1.title-post {
    text-align: left;
    line-height: 4.28vw;
    font-size: 2.864583333333333vw;
    margin-top: 2.24vw;
  }

  #section-post {
    background-position: 49vw -12.5vw;
  }

  p.post-date {
    font-size: 0.6770833333333334vw;
    margin-bottom: 2vw;
  }

  .post-img img {
    height: 32.8125vw;
  }

  p.post-subtitle,
  p.post-autor {
    font-size: 0.6770833333333334vw;
  }

  .content-post {
    font-size: 0.8333333333333334vw;
    line-height: 1.52vw;
  }

  :deep(.content-post > h1) {
    font-size: 0.8333333333333334vw;
  }

  :deep(.content-post > h2) {
    font-size: 0.8333333333333334vw;
    margin: 2vw 0;
  }

  :deep(.content-post > p) {
    font-size: 0.8333333333333334vw;
    margin: 0;
  }
}

.post-img {
  width: 100%;
}

.bloq-item img {
  width: 100%;
}

.bloq-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 3.738vw;
  line-height: 194.5%;
  color: #929292;
  margin: 6.308vw 0;
}

.fraseSq .B2Mesage {
  font-weight: 700;
  color: gray;
  margin-bottom: 0;
}

p.B3F4_P {
  font-weight: 700;
  color: gray;
}

p.B2Autor {
  margin-top: 1.5vw;
  color: #929292;
}

.bloq-item p.B4F5_P {
  font-weight: bolder;
  color: #676467;
}

.goBack {
  text-decoration: none;
  color: blue;
  font-size: 1.3vw;
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 2vw 6.5vw;

}

.goBack img {
  transform: rotate(90deg);
  width: 1vw;
  height: 1vw;
}
@media (max-width: 1024px){
    .goBack{
      font-size: 5.3vw;
      gap: 3vw;
    }
    .goBack img{
      width: 4vw;
      height: 4vw;
    }
}

@media (min-width: 1024px) {
  .bloq-item p {
    font-size: 0.833vw;
    line-height: 194.5%;
    margin: 0;
  }

  .bloq-item {
    display: flex;
    margin: 2vw 0;
  }

  .bloq-item img {
    width: 50%;
    margin: auto;
  }

  .B2F1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .col-caja {
    width: 45%;
  }

  .bloq-item .col-caja img {
    width: 100%;
  }

}
</style>
